@use "./config/" as *;
@forward './main.scss';
@forward './swiper/swiper-bundle';
@forward './showcase/showcase';
@forward './module/about-federation';
@forward './standings/etu-ranking';
@forward './events/eventstrip';
@forward './module/triathlon-live';
@forward './module/federation-event';
@forward './events/featured-events';
@each $federation, $federation-img in $federation-bg {
    .load-bg-pattern.#{$federation} {
        .waf-about-federation {
            .layout-wrapper {
                background-image: url("/static-assets/images/cssimages/world-ranking/#{$federation-img}.png?v=#{$image-version}");
            }
        }
        .waf-broadcast {
            .tab-container-item {
                &::before {
                    background-image: url("/static-assets/images/cssimages/world-ranking/#{$federation-img}.png?v=#{$image-version}");
                }
            }
        }
    }
}
.waf-showcase {
    .showcase-wrapper {
        margin-top: var(--secondary-header-height);
    }
}
.waf-component {
    &.waf-listing {
        @extend %mb-20;
        .title {
            width: calc(100% - 12.2rem);
        }
    }
    &.waf-event-strip {
        padding-top: var(--space-24);
    }
}
.waf-row-event {
    border-radius: var(--space-8) var(--space-8) 0 0;
    @extend %hidden;
    @include bgImg("cssimages/featured-athletes-bg.png", top -10rem left 75%, auto, no-repeat);
    &::before {
        height: 8%;
    }
    &::after {
        height: 96%;
        background: linear-gradient(180deg, hsl(var(--hsl-neutral-100)/0) 0%, hsl(var(--hsl-neutral-100)/0.99) 22.25%, hsl(var(--hsl-neutral-100)/1) 100%);
    }
}
.waf-listing {
    &.waf-overlay-list {
        .waf-body {
            margin-right: calc(-1*var(--container-white-space));
        }
        .article-list {
            grid-auto-flow: column;
            grid-auto-columns: calc(100% - var(--space-6));
            grid-template-columns: unset;
            overflow-x: auto;
        }
    }
}
.waf-event-strip {
    border-radius: var(--space-8) var(--space-8) 0 0;
    @extend %tabs-underline;
    .head-tab {
        a {
            @extend %bg-transparent;
            @extend %gap-2;
            &:hover {
                @extend %bg-transparent;
            }
            &:after {
                @include icon(arrow-right, 19);
            }
        }
    }
    .tab {
        &-section {
            @extend %relative;
            @extend %zindex-content;
            @extend %mb-8;
        }
        &-item {
            &.active {
                .tab-name {
                    &::before {
                        background-color: var(--neutral-5);
                    }
                    .tab-text {
                        color: var(--neutral-5);
                    }
                }
            }
            &:hover {
                .tab-text {
                    color: var(--neutral-5);
                }
            }
        }
        &-name {
            height: 6.4rem;
            cursor: pointer;
            @extend %px-6;
            @include border(3, neutral-100, 10, bottom, solid);
            &.active {
                @include border(3, neutral-5, 10, bottom, solid);
                .tab-text {
                    @extend %neutral-5;
                }
            }
            &:hover {
                .tab-text {
                    @extend %neutral-5;
                }
            }
        }
        &-text {
            color: var(--neutral-20);
            @extend %text-l;
        }
    }
}
.waf-standings {
    .table {
        .card-name {
            max-width: max-content;
        }
        &-row {
            &:first-child {
                .card-name {
                    max-width: max-content;
                }
            }
        }
    }
}
@include mq(col-tablet) {
    .waf-showcase {
        .showcase-wrapper {
            margin-top: 0;
        }
    }
    .waf-row-event {
        background-position: top -18rem left 60%;
        background-size: cover;
        .card-list {
            overflow: auto;
            gap: 1.8rem;
        }
    }
    .waf-listing {
        &.waf-horizontal-list {
            .article-list {
                @include grid(2);
            }
        }
        &.waf-overlay-list {
            .waf-body {
                margin-right: 0;
            }
            .article-list {
                grid-auto-flow: unset;
                grid-auto-columns: unset;
                grid-template-columns: repeat(2, minmax(0, 1fr));
                overflow-x: unset;
            }
        }
    }
    .waf-event-strip {
        .head-tab {
            a {
                background-color: transparent;
                @include color(neutral-0);
                &:hover {
                    background-color: transparent;
                }
            }
        }
        .tab {
            &-text {
                font-weight: 700;
            }
        }
    }
}
@include mq(col-lg) {
    .waf-row-event {
        .card-list {
            overflow: initial;
        }
        &::before {
            height: 25%;
        }
        &::after {
            height: 75%;
        }
        .card-list {
            margin-right: 0;
        }
    }
    .waf-listing {
        &.waf-overlay-list {
            .article-list {
                grid-template-columns: repeat(4, minmax(0, 1fr));
            }
        }
    }
    .waf-event-strip {
        .tab {
            &-text {
                font-size: 2rem;
            }
        }
    }
}