@use '../config/' as *;
.waf-about-federation {
    &.waf-component {
        @extend %mt-10;
        @extend %mb-0;
        @extend %py-0;
    }
}
.waf-about-federation {
    .layout-wrapper {
        background: linear-gradient(45deg, var(--neutral-100), var(--notice-90));
        @extend %py-8;
        @extend %px-4;
        @extend %border-radius-l;
        @extend %relative;
    }
    .head-logo {
        @extend %mb-6;
    }
    .logo {
        object-fit: contain;
        object-position: left;
        max-height: 5.6rem;
    }
    .head-content {
        bottom: 3.2rem;
        @extend %position-b-l;
        @extend %w-100;
        @extend %px-4;
    }
    .head-tab {
        a {
            width: max-content;
            @include btn-config-1(outline, text, neutral, transparent);
            border-color: var(--neutral-5);
            color: var(--neutral-5);
            @extend %mr-auto;
            @extend %btn;
        }
    }
    .title {
        @extend %neutral-5;
        @extend %mb-6;
    }
    .about-title {
        @extend %mb-12;
        @extend %neutral-5;
        @extend %title-40;
    }
    p,
    .about-list {
        @extend %neutral-20;
    }
    .about-list {
        li {
            list-style: disc;
            list-style-position: inside;
        }
    }
    .waf-body {
        margin-bottom: 16rem;
    }
}
@include mq(col-lg) {
    .waf-about-federation {
        &.waf-component {
            margin-block: var(--space-20) var(--space-7);
        }
    }
    .waf-about-federation {
        .head-logo {
            margin-bottom: var(--space-12);
        }
        .logo {
            max-height: 8rem;
        }
        .head-content {
            position: static;
            padding-inline: 0;
        }
        p,
        .about-list {
            font-size: 1.6rem;
        }
        .waf-head {
            width: 36%;
            flex-shrink: 0;
        }
        .waf-body {
            margin-bottom: 0;
        }
        .layout-wrapper {
            padding-block: var(--space-20);
            padding-inline: var(--space-20);
            gap: var(--space-6);
            @include flex-config(flex, row, space-between);
        }
    }
}