@use '../config/' as *;

.waf-standings {
    padding-block: var(--space-20);
    margin-bottom: var(--space-0);
    contain: paint;
    @extend %mr-2-neg;
    @extend %relative;
    @extend %ranking-table;
    &::before {
        content: '';
        width: calc(100% + var(--space-20));
        @extend %h-100;
        @include position(0,null,null,var(--space-10-neg));
        @include bgImg('cssimages/pattern/blue-gradient-left.png', top, cover);
    }
    .waf-head {
        .title {
            @extend %title-40;
            @extend %neutral-100;
            @extend %mb-8;
            @include title-with-icon(null, trophy, 20, null);
            &::before {
                @extend %neutral-40;
            }
        }
        .status,.head-tab {
            @extend %d-none;
        }
    }
    .waf-body {
        margin-right: calc(-1*var(--container-white-space));
    }
    .table {
        &:not(:last-child) {
            @extend %mb-10;
        }
        &-wrapper  {
            padding-bottom: var(--space-8);
            @extend %px-4;
            @extend %border-radius-l;
            @include border(1,neutral-20);
            .title {
                @extend %capitalize;
                @extend %neutral-100;
                @extend %mb-10;
                @extend %title-40;
            }
        }
        &-title {
            @extend %mb-4;
            @extend %title-50;
            @extend %capitalize;
            @extend %neutral-100;
        }
        &-body {
            @extend %bg-neutral-0;
            @extend %border-radius-l;
            @include border(1, neutral-20);
        }
        &-row {
            border-inline: 0;
            height: 5.6rem;
            justify-content: flex-start;
            @extend %bg-transparent;
        }
        &-data {
            &.info {
                width: calc(100% - 5rem);
                .card-name {
                    .text {
                        @include truncate-text(1);
                    }
                }
            }
            &.time {
                @extend %d-none;
            }
        }
        &-footer {
            justify-content: flex-start;
            @extend %mt-0;
            a {
                color: var(--primary-50);
                &:hover {
                    color: var(--primary-55);
                }
            }
        }
    }
    .card-thumbnail {
        @extend %d-none;
    }
    .swiper-button-prev,.swiper-button-next {
        @extend %d-none;
        &:hover{
            border-color: var(--primary-55);
            &::after{
                @extend %primary-55;
            }
        }
        &:focus{
            border-color: var(--primary-85);
            &::after{
                @extend %primary-85;
            }
        }
        &.swiper-button-disabled {
            border-color: var(--neutral-20);
            &::after{
                color: var(--neutral-40);
            }
        }
    }
}
@include mq(col-tablet) {
    .waf-standings {
        .waf-head {
            .title {
                font-size: 4rem;
            }
        }
        .waf-body{
            margin-right: calc(-1*((var(--window-inner-width) - var(--container-max-width)) / 2));
        }
        .table {
            flex: 1;
            &:not(:last-child) {
                margin-bottom: var(--space-0);
            }
            &-list{
                overflow-x: unset;
                gap: unset;
            }
            &-wrapper {
                display: flex;
                flex-wrap: wrap;
                gap: var(--space-6);
                padding: var(--space-10);
                .title {
                    width: 100%;
                    margin-bottom: var(--space-2);
                    font-size: 3.2rem;
                }
            }
            &-title {
                font-size: 2.4rem;
            }
            &-data {
                &.info {
                    width: calc(100% - 13rem);
                }
                &.time {
                    display: flex;
                }
            }
            &-row {
                height: 7.2rem;
            }
        }
        .card-thumbnail {
            display: flex;
            border-color: var(--primary-50);
        }
        .swiper {
            position: static;
        }
        .swiper-button {
            &-prev,&-next {
                display: flex;
                position: absolute;
                left: auto;
                top: 12rem;
                @include border(1,primary-50);
                &::after {
                    font-size: 2.4rem;
                    color: var(--primary-50);
                }
            }
            &-prev {
                right: calc(var(--container-white-space) + 6rem);
            }
            &-next {
                right: var(--container-white-space);
            }
        }
    }
}