@use '../config/' as *;
.waf-broadcast {
    @extend %bg-transparent;
    &::before {
        content: '';
        border-radius: 0 0 var(--space-8) var(--space-8);
        @extend %w-100;
        @extend %h-60;
        @extend %position-t-l;
        @extend %bg-neutral-100;
    }
    .status {
        border-radius: 10rem;
        width: max-content;
        @extend %neutral-0;
        @extend %p-2-3;
        @extend %flex-n-c;
        @extend %gap-1;
        @include bg(neutral-0, 2);
        &::after {
            @include icon(live, 16);
        }
    }
    .btn-site {
        width: max-content;
        transform: translateX(50%);
        @include position(null, 50%, 0);
    }
    .tab-container-item {
        @extend %border-radius-m;
    }
    .streaming {
        margin-top: 0;
        margin-bottom: 0;
        @extend %py-8;
        .title {
            @extend %neutral-5;
        }
        .text {
            margin-bottom: 0;
            font-weight: 700;
            color: var(--neutral-0);
        }
        &-info {
            flex-direction: column;
        }
        &-thumbnail {
            margin-top: 0;
            margin-bottom: 9.9rem;
            img {
                aspect-ratio: 3/2;
                max-height: 20.8rem;
                height: auto;
                width: 100%;
                @extend %border-radius-m;
            }
        }
        &-content {
            &-info {
                @extend %mb-12;
            }
            > .title {
                @extend %title-40;
            }
        }
    }
}
@include mq(col-tablet) {
    .waf-broadcast {
        .btn-site {
            position: static;
            transform: unset;
            margin-top: var(--space-8);
        }
        .streaming {
            padding: var(--space-14);
            .title {
                font-size: 2rem;
                line-height: 1.4;
            }
            &-info {
                flex-direction: row;
                align-items: center;
                .streaming-info {
                    flex-direction: column;
                    align-items: flex-start;
                }
            }
            &-thumbnail {
                margin-bottom: 0;
                img {
                    aspect-ratio: unset;
                    height: 100%;
                    max-height: unset;
                }
            }
            &-content {
                &-info {
                    margin-bottom: 0;
                }
                > .title {
                    font-size: 3.2rem;
                    line-height: 4.1rem;
                }
            }
        }
        .tab-container-item {
            border-radius: 2.4rem;
            &::before {
                background-position: top -8rem left;
                background-size: 100% calc(100% + 8rem);
            }
        }
    }
}
@include mq(col-desktop) {
    .waf-broadcast {
        .status {
            order: unset;
            margin-bottom: 0;
            flex-shrink: 0;
        }
        .streaming {
            .title {
                margin-bottom: 0;
            }
            &-content {
                > .title {
                    margin-bottom: var(--space-6);
                }
                &-info {
                    gap: var(--space-6);
                    @include flex-config(flex, row, null, center);
                    .streaming-info {
                        flex-direction: row;
                        align-items: center;
                    }
                }
            }
            &-info{
                .streaming-info {
                    flex-direction: row;
                    align-items: center;
                    gap: var(--space-6);
                }
            }
        }
    }
}